$icons:
  "add-filled", "add", "altname", "bell", "bell-filled", "bell-ring", "bluesky",
  "bookmark-filled", "bookmark", "cancel", "chatgpt", "checkmark", "chevron",
  "chrome", "critical", "curriculum-next", "curriculum-prev", "deno",
  "deprecated", "desktop", "disabled", "edge", "edit", "edit-filled",
  "ellipses", "experimental", "external", "eye-filled", "eye", "feed", "filter",
  "footnote", "github-mark-small", "ie", "information", "highlight", "history",
  "language", "mastodon", "menu-filled", "menu", "mobile", "more", "theme-dark",
  "new-topic", "next", "no", "nodejs", "nonstandard", "note-info",
  "note-warning", "note-deprecated", "opera", "padlock", "partial", "play",
  "prefix", "preview", "previous", "question-mark", "queue", "queued", "quote",
  "return", "safari", "samsunginternet", "search", "send", "server", "sidebar",
  "simple-firefox", "small-arrow", "theme-light", "star-filled", "star",
  "theme-os-default", "thumbs-down", "thumbs-up", "trash", "trash-filled",
  "twitter-x", "unknown", "warning", "webview", "yes", "yes-circle";

.icon {
  --size: var(--icon-size, 1rem);
  background-color: var(--icon-primary);
  display: inline-block;
  flex-shrink: 0;
  height: var(--size);
  mask-position: center;
  mask-repeat: no-repeat;
  vertical-align: middle;
  width: var(--size);

  @each $name in $icons {
    &.icon-#{$name} {
      mask-image: url("../../../assets/icons/" + $name + ".svg");
    }
  }
}

// The icon class is applied directly to SVGs sometimes. This
// override un-breaks those.
svg.icon {
  background: transparent;
  color: var(--icon-primary);
}

.icons-highlighted {
  align-items: center;
  display: flex;
  flex-direction: column;

  > span {
    height: var(--icon-size);
    line-height: 0;
    width: var(--icon-size);

    &.icons-icon-wrapper {
      margin-top: -0.1rem;
    }
  }
}
