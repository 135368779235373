@use "../ui/vars" as *;
@use "../ui/mixins" as *;
@use "../about/mixins" as about;

main.about-container {
  --about-stats-height: 5.75rem;
  --about-section-gap: 5rem;
  --max-width: 74rem;
  --inner-width: calc(min(var(--max-width), 100vw) - 2 * var(--gutter));

  background: var(--about-bg-primary);
  color: var(--about-color);

  @media (max-width: $screen-md) {
    --about-section-gap: 3rem;
  }

  @include about.layout;

  @include about.theme-setup using ($mode) {
    @if $mode == light {
      --header-next-section-bg: #f2f2f5;
      --header-text-primary: #000;
      --header-text-secondary: #343434;
      --header-bg: #fff;
      --stats-bg: #fff;
      --stats-box-shadow:
        4px -2px 15px 0 rgba(179, 179, 179, 0.2),
        4px -4px 15px 0 rgba(179, 179, 179, 0.15);
      --stats-text-primary: #000;
      --stats-stat-bg: #d7f5dc;
      --stats-stat-text: #007936;
      --boxes-bg: #fff;
      --boxes-border: none;
      --boxes-shadow:
        -4px 4px 8px 0 rgba(179, 179, 179, 0.15),
        4px 4px 8px 0 rgba(179, 179, 179, 0.18);
      --boxes-header-bg-1: #d5e8fb;
      --boxes-header-color-1: inherit;
      --boxes-header-bg-2: #fbe3d5;
      --boxes-header-color-2: inherit;
      --boxes-header-bg-3: #fbf7d5;
      --boxes-header-color-3: inherit;
      --boxes-header-bg-4: #fbd5d5;
      --boxes-header-color-4: inherit;
      --boxes-header-bg-5: #dfd5fb;
      --boxes-header-color-5: inherit;
      --boxes-main-color: #000;
      --stairs-color: #007936;
      --about-bg-primary: #fff;
      --about-bg-secondary: #f2f2f5;
      --about-join-us-bg: #f2f2f5;
      --about-join-us-border: none;
      --about-join-us-color: #343434;
      --about-join-us-image: url("../assets/about/building.svg");
      --about-tablist-border: #858585;
      --about-tablist-color: rgba(0, 0, 0, 0.6);
      --about-tablist-active-border: #007936;
      --about-tablist-active-color: #000;
      --about-color: #000;
      --about-heading-color: #000;
      --about-core-values-bg: #fff;
      --about-core-values-bg-secondary: #d7f5dc;
      --about-core-values-color: #000;
      --about-core-values-shadow: 0 4px 15px 0 rgba(179, 179, 179, 0.2);
      --about-team-title-color: #007936;
      --about-team-bg: #fff;
      --about-team-github-bg: rgba(255, 255, 255, 0.75);
      --about-team-shadow: 0 4px 15px 0 rgba(179, 179, 179, 0.2);
      --about-team-color: #000;
      --about-journey-line-color: #007936;
      --about-journey-image-border: #fff;
      --about-journey-shadow:
        4px -4px 15px 0 rgba(179, 179, 179, 0.25),
        4px 4px 15px 0 rgba(179, 179, 179, 0.25);
      --about-journey-dot: url("../assets/about/dot.svg");
      --about-global-impact-image: url("../assets/about/global-impact.svg");
    }

    @if $mode == dark {
      --header-next-section-bg: #1b1b1b;
      --header-text-primary: #fff;
      --header-text-secondary: #b3b3b3;
      --header-bg: #101010;
      --stats-bg: #000;
      --stats-box-shadow:
        4px -2px 15px 0 rgba(38, 38, 38, 0.2),
        4px -4px 15px 0 rgba(38, 38, 38, 0.15);
      --stats-text-primary: #b3b3b3;
      --stats-stat-bg: #394035;
      --stats-stat-text: #8ff295;
      --boxes-bg: #000;
      --boxes-border: #4e4e4e;
      --boxes-shadow: none;
      --boxes-header-bg-1: #141e34;
      --boxes-header-color-1: #9bb6f2;
      --boxes-header-bg-2: #321d13;
      --boxes-header-color-2: #e3642a;
      --boxes-header-bg-3: #343114;
      --boxes-header-color-3: #d4c53b;
      --boxes-header-bg-4: #341419;
      --boxes-header-color-4: #f19ca1;
      --boxes-header-bg-5: #1d1434;
      --boxes-header-color-5: #bf94ec;
      --boxes-main-color: #b3b3b3;
      --stairs-color: #8ff295;
      --about-bg-primary: #1b1b1b;
      --about-bg-secondary: #1b1b1b;
      --about-join-us-bg: #000;
      --about-join-us-border: 1px solid #4e4e4e;
      --about-join-us-color: #b3b3b3;
      --about-join-us-image: url("../assets/about/building-dark.svg");
      --about-tablist-border: #858585;
      --about-tablist-color: #b3b3b3;
      --about-tablist-active-border: #8ff295;
      --about-tablist-active-color: #fff;
      --about-color: #b3b3b3;
      --about-heading-color: #fff;
      --about-core-values-bg: #000;
      --about-core-values-bg-secondary: #007936;
      --about-core-values-color: #fff;
      --about-core-values-shadow: 0 4px 15px 0 rgba(179, 179, 179, 0.2);
      --about-team-title-color: #8ff295;
      --about-team-bg: #000;
      --about-team-github-bg: rgba(0, 0, 0, 0.7);
      --about-team-shadow: 0 4px 15px 0 rgba(179, 179, 179, 0.2);
      --about-team-color: #fff;
      --about-journey-line-color: #8ff295;
      --about-journey-image-border: #000;
      --about-journey-shadow:
        4px -4px 15px 0 rgba(179, 179, 179, 0.25),
        4px 4px 15px 0 rgba(179, 179, 179, 0.25);
      --about-journey-dot: url("../assets/about/dot-dark.svg");
      --about-global-impact-image: url("../assets/about/global-impact-dark.svg");
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--about-heading-color);
    letter-spacing: 0;
  }

  strong {
    letter-spacing: 0;
  }

  > header {
    --header-stats-height: var(--about-stats-height);
    @include about.header;

    h1 + p {
      font-size: 2rem;
      font-weight: 500;
      line-height: 120%;
      max-width: 43rem;

      @media (max-width: $screen-md) {
        font-size: 1.25rem;
      }
    }

    ul {
      @include about.stats;
    }
  }

  > section {
    --center-padding: max(
      calc((100vw - var(--max-width)) / 2 + var(--gutter)),
      var(--gutter)
    );
    @include about.section;

    &[aria-labelledby="who_we_are"] {
      background: var(--about-bg-secondary);
      margin: 0;
      max-width: none;
      padding: 4rem var(--center-padding);
    }

    .tabs {
      grid-column: 1 / -1;
      padding-top: 2rem;
      position: relative;

      .tablist-wrapper {
        background: var(--about-bg-secondary);
        margin: 0 calc(-1 * var(--center-padding));
        margin-bottom: 2rem;
        padding: 0 var(--center-padding);
        position: sticky;
        top: var(--top-nav-height);
        z-index: 2;
      }

      .tablist {
        border-bottom: 1px solid var(--about-tablist-border);
        display: flex;
        gap: 3rem;
        overflow-x: auto;

        @media (max-width: $screen-md) {
          margin-left: calc(-1 * var(--gutter));
          margin-right: calc(-1 * var(--gutter));
          padding: 0 var(--gutter);
        }

        a {
          color: var(--about-tablist-color);
          flex-shrink: 0;
          text-decoration: none;
        }

        .active {
          border-bottom: 2px solid var(--about-tablist-active-border);
          color: var(--about-tablist-active-color);
        }
      }

      > .tabpanel:not(.active) {
        display: none !important;
      }
    }

    #what_we_offer-panel {
      ul {
        @include about.boxes;

        @media (min-width: $screen-md) {
          gap: 5rem;
        }

        @media (min-width: $screen-lg) {
          gap: 5rem 8.5rem;
        }

        li {
          justify-content: flex-start;
        }

        @for $i from 1 through 5 {
          li:nth-of-type(#{$i}) {
            --boxes-header-bg: var(--boxes-header-bg-#{$i});
            --boxes-header-color: var(--boxes-header-color-#{$i});
          }
        }
      }
    }

    #our_journey-panel {
      --image-inner-height: calc(720px * 0.45);
      --image-border-size: 4px;
      --image-height: calc(
        var(--image-inner-height) + var(--image-border-size) * 2
      );
      --image-width: calc(
        var(--image-inner-height) * 16 / 9 + var(--image-border-size) * 2
      );
      --dot-height: 3rem;
      --separator-width: 10rem;
      --list-width: calc(
        var(--inner-width) - var(--separator-width) - var(--image-width)
      );

      @media (max-width: $screen-xl) {
        --separator-width: 6rem;
      }

      > p {
        margin-bottom: 1rem;
      }

      > ul {
        border-right: var(--about-journey-line-color) dashed 0.0625rem;
        margin-top: 3rem;
        width: var(--list-width);

        @media (max-width: $screen-lg) {
          border-left: var(--about-journey-line-color) dashed 0.0625rem;
          border-right: none;
          margin-left: 1rem;
          width: auto;
        }

        li {
          margin: 8rem 0;
          min-height: var(--image-height);
          padding-right: 2rem;
          position: relative;

          &:first-of-type {
            margin-top: 2rem;
          }

          &:last-of-type {
            margin-bottom: 2rem;
          }

          @media (max-width: $screen-lg) {
            padding-left: 2rem;
          }

          &::after {
            // dot on vertical dashed line
            background-image: var(--about-journey-dot);
            background-position: center center;
            background-repeat: no-repeat;
            content: "";
            display: block;
            height: var(--dot-height);
            position: absolute;
            right: calc(var(--dot-height) / -2);
            top: calc(var(--dot-height) / -2);
            width: var(--dot-height);

            @media (max-width: $screen-lg) {
              --dot-height: 2rem;
              left: calc(var(--dot-height) / -2);
              right: auto;
            }
          }

          &:has([id="2017"], [id="20232024"]) {
            min-height: 0;
          }
        }

        h4 {
          font-size: 1.375rem;
          font-weight: 600;
          line-height: 1.5;
          margin: 0;
          position: relative;
          text-decoration-line: underline;
          top: -0.75em;

          @media (max-width: $screen-lg) {
            font-size: 1.25rem;
            margin-bottom: -0.75em;
          }

          &::before {
            // horizontal line to dot
            background: var(--about-journey-line-color);
            content: "";
            display: none;
            height: 10px;
            left: calc(100% + 2rem);
            mask-image: url("../assets/about/line-dot.svg");
            mask-position: center right;
            mask-repeat: no-repeat;
            mask-size: cover;
            position: absolute;
            top: calc(0.75em - 5px);
            width: calc(var(--separator-width) - var(--dot-height) / 2);

            @media (max-width: $screen-lg) {
              content: none;
            }
          }

          ~ p:last-of-type > img {
            // image
            background-color: var(--about-journey-image-border);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            border: var(--image-border-size) solid
              var(--about-journey-image-border);
            box-shadow: var(--about-journey-shadow);
            height: var(--image-height);
            left: calc(var(--inner-width) - var(--image-width));
            max-width: var(--image-width);
            position: absolute;
            top: calc(var(--dot-height) / -2);
            width: var(--image-width);

            @media (max-width: $screen-lg) {
              aspect-ratio: 16 / 9;
              box-sizing: content-box;
              height: auto;
              left: auto;
              margin: 2rem auto;
              max-height: var(--image-inner-height);
              max-width: 100%;
              position: relative;
              top: auto;
              width: auto;
            }
          }

          &[id="2005"] {
            &::before,
            ~ p:last-of-type::after {
              display: block;
            }
          }

          &[id="2010"] {
            &::before,
            ~ p:last-of-type::after {
              display: block;
            }
          }

          &[id="2020"] {
            &::before,
            ~ p:last-of-type::after {
              display: block;
            }
          }

          &[id="2022"] {
            &::before,
            ~ p:last-of-type::after {
              display: block;
            }
          }
        }
      }

      h4 {
        font-size: 1.75rem;
        font-weight: 600;
      }

      mdn-image-history ul {
        display: flex;
        gap: 1rem;
        margin-bottom: -1rem;
        overflow-x: auto;
        padding-bottom: 1rem; // space for scrollbar

        li {
          flex-shrink: 0;
        }

        img {
          aspect-ratio: 16 / 9;
          border: var(--image-border-size) solid
            var(--about-journey-image-border);
          box-sizing: content-box;
          height: calc(720px / 4);
        }
      }
    }

    #our_core_values-panel {
      li {
        border-radius: 0.5rem;
        color: var(--about-core-values-color);

        h4 {
          color: var(--about-core-values-color);
          font-size: 1rem;
          font-weight: 600;
          letter-spacing: -0.03125rem;
          margin: 0;
          margin-bottom: 0.5rem;
        }

        &:not(:last-of-type) {
          background: var(--about-core-values-bg-secondary);
          margin: 1rem auto;
          padding: 2rem;

          h4 {
            --icon-size: 45px;
            align-items: center;
            background-position: left center;
            background-repeat: no-repeat;
            background-size: var(--icon-size) var(--icon-size);
            display: flex;
            min-height: var(--icon-size);
            padding-left: calc(var(--icon-size) + 1rem);

            &#accurate_and_reliable {
              background-image: url("../assets/about/accurate-sm.svg");
            }

            &#collaborative_and_community-driven {
              background-image: url("../assets/about/collaborative-sm.svg");
            }

            &#inclusive_and_dynamic {
              background-image: url("../assets/about/inclusive-sm.svg");
            }
          }
        }

        &:last-of-type {
          background: var(--about-core-values-bg);
          margin-top: 2rem;
          padding: 2rem;
        }

        @media (min-width: $screen-lg) {
          display: grid;
          grid-auto-flow: dense;
          grid-template-columns: [left-start] 40% [left-end right-start] 1fr [right-end];
          padding: 2rem;

          h4 {
            font-size: 1.75rem;
            margin: 0;
            max-width: 70%;
          }

          p {
            margin-left: var(--gutter);
          }

          &:not(:last-of-type) {
            background: var(--about-core-values-bg);
            margin: 0 auto;

            &:not(:first-of-type) {
              border-top-left-radius: 0;
              border-top-right-radius: 0;
            }

            &:not(:nth-last-of-type(2)) {
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
            }

            h4 {
              --icon-size: 60px;

              align-self: center;

              &#accurate_and_reliable {
                background-image: url("../assets/about/accurate.svg");
              }

              &#collaborative_and_community-driven {
                background-image: url("../assets/about/collaborative.svg");
              }

              &#inclusive_and_dynamic {
                background-image: url("../assets/about/inclusive.svg");
              }
            }

            p {
              background: var(--about-core-values-bg-secondary);
              border-radius: 0.5rem;
              padding: 2rem;
            }
          }

          &:nth-of-type(even):not(:last-of-type) {
            h4 {
              grid-column: right;
              margin: 0 auto;
            }

            p {
              grid-column: left;
              margin-left: 0;
              margin-right: var(--gutter);
            }
          }

          &:last-of-type {
            margin-top: 4rem;
          }
        }
      }
    }

    #our_team-panel,
    #our_partners-panel > div {
      --team-grid-gap: 2.5rem;
      --team-card-padding: 1.5rem;

      display: grid;
      gap: 0 var(--team-grid-gap);
      grid-auto-flow: dense;
      grid-template-columns: [full-start] 1fr 1fr 1fr [full-end];

      @media (max-width: $screen-lg) {
        --team-grid-gap: 2rem;
        grid-template-columns: [full-start] 1fr 1fr [full-end];
      }

      > * {
        margin: calc(var(--team-grid-gap) / 2) 0;
        scroll-margin-top: calc(
          var(--sticky-header-without-actions-height) + 1.5rem + 1rem
        );
      }

      > h4,
      p {
        grid-column: full;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      h4,
      h5 {
        font-size: 1.75rem;
        font-weight: 600;
        text-transform: none;

        @media (max-width: $screen-md) {
          font-size: 1rem;
        }
      }

      team-member {
        align-content: start;
        background: var(--about-team-bg);
        border: 1px solid var(--about-team-bg);
        box-shadow: var(--about-team-shadow);
        color: var(--about-team-color);
        cursor: pointer;
        display: grid;
        gap: 0;
        grid-row: span 5;
        grid-template-areas:
          "name"
          "title"
          "picture"
          "bio"
          ".";
        grid-template-rows: subgrid;
        padding: calc(var(--team-card-padding) - 1px);
        user-select: none;

        @media (max-width: $screen-sm) {
          grid-column: full;
        }

        &:hover {
          border-color: var(--text-inactive);
        }

        h4,
        h5 {
          grid-area: name;
          margin: 0;
          margin-bottom: 0.5rem;
        }

        ul {
          display: contents;
        }

        li:first-of-type {
          color: var(--about-team-title-color);
          font-weight: 600;
          grid-area: title;
          margin-bottom: 0.75rem;
        }

        li:nth-of-type(2) {
          grid-area: picture;
        }

        li:nth-of-type(3):not(:last-of-type) {
          align-self: end;
          grid-area: picture;
        }

        li:last-of-type {
          grid-area: bio;
          line-height: 1.75;
          @include line-clamp(3, 1.75, var(--about-team-bg));
        }

        img {
          aspect-ratio: 1;
          margin-bottom: 0.75rem;
          width: 100%;
        }

        a[href^="https://github.com"]
        {
          background: var(--about-team-github-bg);
          border-top-right-radius: 0.5rem;
          color: var(--about-team-color);
          margin-top: -2.9rem;
          padding: 0.25rem 0.5rem;
          padding-right: 0.7rem;
          position: absolute;

          &::before {
            background: var(--about-team-color);
            content: "";
            display: inline-block;
            height: 1.2em;
            margin-bottom: 0.2em;
            margin-right: 0.2em;
            mask-image: url("../assets/icons/github-mark-small.svg");
            mask-repeat: no-repeat;
            vertical-align: middle;
            width: 1.2em;
          }
        }

        &:focus-within {
          align-content: start;
          cursor: unset;
          display: grid;
          gap: 0 var(--team-card-padding);
          grid-column: span 2;
          grid-template-areas:
            "name    name"
            "title   title"
            "picture bio"
            ".       bio"
            ".       bio";
          grid-template-columns:
            calc(
              (100% - var(--team-grid-gap) - 2 * var(--team-card-padding)) / 2
            )
            1fr;
          user-select: auto;

          li:last-of-type {
            max-height: unset;

            &::after {
              display: none;
            }
          }

          @media (max-width: $screen-sm) {
            grid-template-areas:
              "name"
              "title"
              "picture"
              "bio";
            grid-template-columns: 1fr;

            ul li:nth-of-type(3):not(:last-of-type) {
              align-self: end;
              grid-area: picture;

              a {
                position: absolute;
              }
            }
          }
        }
      }
    }

    #our_partners-panel > div {
      margin-top: 2.5rem;
    }

    &[aria-labelledby="global_impact"] {
      display: block;
      margin: var(--about-section-gap) auto;

      @media (min-width: $screen-lg) {
        .section-content {
          background: var(--about-global-impact-image);
          background-position: right;
          background-repeat: no-repeat;
          background-size: 20rem 100%;
          padding-right: 30rem;
        }
      }

      ul {
        margin-top: 1rem;
        @include about.stairs;

        li:nth-child(1) {
          --stairs-icon: url("../assets/about/education.svg");
        }

        li:nth-child(2) {
          --stairs-icon: url("../assets/about/text-box-check-outline.svg");
        }

        li:nth-child(3) {
          --stairs-icon: url("../assets/about/web-check.svg");
        }

        li:nth-child(4) {
          --stairs-icon: url("../assets/about/handshake.svg");
        }
      }
    }

    &[aria-labelledby="join_us_in_building_a_better_web"] {
      background: var(--about-join-us-image) var(--about-join-us-bg);
      background-position: 1.5rem;
      background-repeat: no-repeat;
      background-size: 20rem calc(100% - 3rem);
      border: var(--about-join-us-border);
      border-radius: 0.5rem;
      color: var(--about-join-us-color);
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: var(--about-section-gap);
      max-width: calc(100% - 2 * var(--center-padding));
      padding: 4rem 1rem;
      padding-left: min(30rem, 50%);

      @media (max-width: $screen-md) {
        background-color: transparent;
        background-position: center bottom;
        background-size: 100% 10rem;
        border: none;
        padding: 1rem 0;
        padding-bottom: 12rem;
      }
    }
  }
}
